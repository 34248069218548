// This function wraps Amplify Auth session and should only be used in server side
//code. It is designed to be called in getServerSideProps and return a session
// object that should be returned as a prop in the page, and is used to provide
// data to the sessionContext provider in _app.js.
import getUser from '@/utils/userConfiguration/getUser';
import getOrganization from '@/utils/userConfiguration/getOrganization';
import { withSSRContext } from 'aws-amplify';

async function getServerSession(context) {
  try {
    const { Auth } = withSSRContext(context);
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const token = cognitoUser.signInUserSession?.idToken?.jwtToken;

    const cookies = context.req.cookies;

    // this function also returns appropriate impersonation data, if needed.
    // impersonation is done through the use of cookies
    const isImpersonating = !!cookies.impersonatedUser;

    let userEmail = cognitoUser.attributes?.email;
    if (!userEmail) {
      // do this unholy ritual to get the email from SSO'd users
      userEmail = cognitoUser.username.split('_').slice(1).join('');
    }
    let userOrg = cognitoUser.attributes?.['custom:org'];
    if (!userOrg) {
      userOrg = cognitoUser.username.split('_')[0].split('-')[0];
    }

    const emailToUse = isImpersonating
      ? JSON.parse(cookies.impersonatedUser).email
      : userEmail;
    const orgToUse = isImpersonating
      ? JSON.parse(cookies.impersonatedUser).org
      : userOrg;

    const user = await getUser(emailToUse, orgToUse);
    const org = await getOrganization(orgToUse);

    // more of these magic strings
    user.isInternal = user.role === 'CIS_INTERNAL';
    user.isAdmin = user.role === 'ADMIN' || user.role === 'CIS_INTERNAL';

    return {
      isImpersonating,
      user,
      token,
      originalUser: userEmail,
      org,
    };
  } catch (err) {
    console.error(err);
    return {
      redirect: {
        destination: `/sso?callbackUrl=${encodeURI(context.resolvedUrl)}`,
        permanent: false,
      },
    };
  }
}

export default getServerSession;
